import { FC, useMemo, useState } from "react";

import {
  Column,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuList,
  Row,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  useToast,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate, useSearchParams } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  JourneysBoolExp,
  JourneysOrderBy,
  OrderBy,
  useJourneysQuery,
} from "src/graphql";
import { JourneyStatus } from "src/types/journeys";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrl } from "src/utils/urls";

import { JourneyStatusBadge } from "./components";
import { JourneyFilterType } from "./constants";
import { getJourneyState } from "./utils";
import { PermissionedMenuItem } from "src/components/permission";
import { getCreateJourneyPermission } from "./permission-utils";
import { commaNumber } from "src/utils/numbers";
import { CloneJourneyInput } from "./types";
import { DeleteJourneyModal } from "./components/delete-journey-modal";
import { useDeleteJourney } from "./graph/use-delete-journey";
import { Schedule } from "src/components/schedule/types";

enum SortKeys {
  Name = "name",
  Status = "status",
  UpdatedAt = "updated_at",
  CreatedAt = "created_at",
  ParentModel = "parent_model.name",
  NumberUsers = "nodes_aggregate.sum.number_users",
}

type JourneysTableProps = {
  onClone: (journeyInput: CloneJourneyInput) => void;
};

export const JourneysTable: FC<JourneysTableProps> = ({ onClone }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { orderBy, onSort } = useTableConfig<JourneysOrderBy>({
    sortOptions: Object.values(SortKeys),
  });

  const deleteJourney = useDeleteJourney();

  const [journeyToDelete, setJourneyToDelete] = useState<{
    id: string;
    versionId: string;
    name: string;
    description: string | null;
    schedule: Schedule;
  } | null>(null);

  const { journeysFullCloning } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const filter = searchParams.get("filter") ?? "all";
  const { limit, offset, page, setPage } = useTableConfig({ limit: 50 });

  const filters: JourneysBoolExp = useMemo(() => {
    const baseFilter: JourneysBoolExp = {
      is_latest: { _eq: true },
      ...(search ? { name: { _ilike: `%${search}%` } } : {}),
    };
    const offAndDraftsSharedFilters: JourneysBoolExp = {
      status: { _eq: JourneyStatus.Disabled },
      nodes_aggregate: {
        count: {
          filter: {
            _and: [
              { number_users: { _neq: "0" } },
              { number_users: { _neq: null } },
            ],
          },
          predicate: { _eq: 0 },
        },
      },
    };

    switch (filter) {
      case JourneyFilterType.LIVE:
        return {
          ...baseFilter,
          deleted: { _neq: true },
          status: { _eq: JourneyStatus.Enabled },
        };
      case JourneyFilterType.DRAFTS:
        return {
          ...baseFilter,
          ...offAndDraftsSharedFilters,
          deleted: { _neq: true },
          _or: [{ reset: { _neq: true } }, { reset: { _neq: null } }],
          runs_aggregate: { count: { predicate: { _eq: 0 } } },
        };
      case JourneyFilterType.OFF:
        return {
          ...baseFilter,
          ...offAndDraftsSharedFilters,
          runs_aggregate: { count: { predicate: { _gt: 0 } } },
        };
      case JourneyFilterType.ALL:
      default:
        return baseFilter;
    }
  }, [filter, search]);

  const journeysQuery = useJourneysQuery({
    orderBy,
    filters,
    limit,
    offset,
  });

  const clickRow = (
    { id, deleted }: { id: string; deleted: boolean },
    event,
  ) => {
    if (deleted) {
      toast({
        id: "navigate-error",
        title: "This journey is being deleted and cannot be accessed",
        variant: "info",
      });
      return;
    }

    openUrl(`/journeys/${id}`, navigate, event);
  };

  const journeys = journeysQuery.data?.journeys;
  const count = journeysQuery.data?.journeys_aggregate?.aggregate?.count;

  return (
    <>
      <ToggleButtonGroup
        value={filter}
        onChange={(value) => setSearchParams({ filter: value })}
        mb={4}
      >
        <ToggleButton label="All" value="all" />
        <ToggleButton label="Drafts" value="drafts" />
        <ToggleButton label="Live" value="live" />
        <ToggleButton label="Off" value="off" />
      </ToggleButtonGroup>
      <Table
        data={journeys}
        columns={[
          {
            onClick: () => onSort(SortKeys.Name),
            sortDirection: orderBy?.name,
            name: "Name",
            cell: ({ name, deleted, reset }) => (
              <Column overflow="hidden">
                <TextWithTooltip fontWeight="semibold">{name}</TextWithTooltip>
                {deleted ? (
                  <Text color="text.secondary" size="sm">
                    Deletion in progress
                  </Text>
                ) : reset ? (
                  <Text color="text.secondary" size="sm">
                    Reset in progress
                  </Text>
                ) : null}
              </Column>
            ),
          },
          {
            onClick: () => onSort(SortKeys.Status),
            sortDirection: orderBy?.status,
            name: "Status",
            cell: ({ reset, status, nodes_aggregate, runs_aggregate }) => (
              <JourneyStatusBadge
                status={getJourneyState({
                  reset: Boolean(reset),
                  status: status as JourneyStatus,
                  numberOfUsersInJourney:
                    nodes_aggregate.aggregate?.sum?.number_users ?? 0,
                  numberOfPreviousRuns: runs_aggregate.aggregate?.count ?? 0,
                })}
              />
            ),
          },
          {
            onClick: () => {
              // Historically, there's been slowness related to [asc|desc]_nulls_last.
              // As of 2025-03-17, there are not many journeys + nodes, so this issue
              // has not been observed. If we do observe slowness in the future, check here.
              const sortDirection =
                ((searchParams.get("sortDirection") || null) as OrderBy) ||
                "desc_nulls_last";
              const nextSortDirection = sortDirection?.startsWith("asc")
                ? "desc_nulls_last"
                : "asc_nulls_last";
              searchParams.set("sortKey", SortKeys.NumberUsers);
              searchParams.set("sortDirection", nextSortDirection);
              setSearchParams(searchParams);
            },
            name: "Active members",
            cell: ({ nodes_aggregate }) => {
              const count = commaNumber(
                nodes_aggregate.aggregate?.sum?.number_users ?? 0,
              );
              return (
                <Column overflow="hidden">
                  <TextWithTooltip color="text.primary" message={count}>
                    {count}
                  </TextWithTooltip>
                </Column>
              );
            },
          },
          {
            onClick: () => onSort(SortKeys.ParentModel),
            sortDirection: orderBy?.parent_model?.name,
            name: "Parent model",
            cell: ({ parent_model }) => {
              return (
                <Row align="center" gap={2} overflow="hidden">
                  <IntegrationIcon
                    src={parent_model?.connection?.definition?.icon}
                    name={parent_model?.connection?.definition?.name}
                  />
                  <TextWithTooltip fontWeight="semibold">
                    {parent_model?.name ?? ""}
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          {
            onClick: () => onSort(SortKeys.UpdatedAt),
            sortDirection: orderBy?.updated_at,
            ...LastUpdatedColumn,
            breakpoint: "md",
          },
          {
            max: "max-content",
            cell: ({
              parent_model,
              id: journey_id,
              version_id: journey_version_id,
              name: journey_name,
              description: journey_description,
              schedule: journey_schedule,
              status,
              description,
            }) => {
              return (
                <Row
                  width="100%"
                  justifyContent="flex-end"
                  alignContent="flex-end"
                  gap={2}
                  overflow="hidden"
                >
                  <Menu>
                    <MenuActionsButton
                      onClick={(event) => event.stopPropagation()}
                    />
                    <MenuList>
                      <PermissionedMenuItem
                        permission={getCreateJourneyPermission(
                          parent_model?.id?.toString(),
                        )}
                        icon={EditIcon}
                        onClick={() => {}}
                      >
                        Edit journey
                      </PermissionedMenuItem>
                      {journeysFullCloning && (
                        <PermissionedMenuItem
                          permission={getCreateJourneyPermission(
                            parent_model?.id?.toString(),
                          )}
                          icon={CopyIcon}
                          onClick={(event) => {
                            event.stopPropagation();
                            onClone({
                              sourceJourneyId: journey_id,
                              name: journey_name,
                              parentModelId: parent_model?.id,
                              description: description,
                            });
                          }}
                        >
                          Make a copy
                        </PermissionedMenuItem>
                      )}
                      <MenuDivider />
                      <PermissionedMenuItem
                        permission={getCreateJourneyPermission(
                          parent_model?.id?.toString(),
                        )}
                        isDisabled={status !== JourneyStatus.Disabled}
                        icon={DeleteIcon}
                        variant="danger"
                        tooltip={
                          status !== JourneyStatus.Disabled
                            ? "Active journeys cannot be deleted"
                            : undefined
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          setJourneyToDelete({
                            id: journey_id,
                            versionId: journey_version_id,
                            name: journey_name,
                            description: journey_description,
                            schedule: journey_schedule,
                          });
                        }}
                      >
                        Delete journey
                      </PermissionedMenuItem>
                    </MenuList>
                  </Menu>
                </Row>
              );
            },
          },
        ]}
        loading={journeysQuery.isLoading || journeysQuery.isRefetching}
        error={Boolean(journeysQuery.error)}
        placeholder={{
          image: genericPlaceholder,
          title: `No ${
            filter === JourneyFilterType.ALL ? "" : `${filter} `
          }journeys`,
          body: "Create a new journey to start automating your workflows.",
          error: "Journeys failed to load, please try again.",
        }}
        onRowClick={clickRow}
      />
      <Row justifyContent="right" mt={4}>
        <Pagination
          count={count}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>

      <DeleteJourneyModal
        isOpen={Boolean(journeyToDelete)}
        journeyVersionId={journeyToDelete?.versionId ?? null}
        onClose={() => setJourneyToDelete(null)}
        onConfirm={() => journeyToDelete && deleteJourney(journeyToDelete)}
      />
    </>
  );
};
