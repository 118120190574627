import {
  EditableHeading,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
} from "@hightouchio/ui";
import { FC, useMemo } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import { DetailPage } from "src/components/layout";
import { Warning } from "src/components/warning";
import {
  DecisionEngineFlowQuery,
  useDecisionEngineFlowQuery,
  useDeleteDecisionEngineFlowMutation,
  useUpdateDecisionEngineFlowMutation,
} from "src/graphql";

import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import { DeleteButton } from "src/components/delete-button";
import { OutletContext } from "src/pages/decision-engines";
import { FlowActionButton } from "src/pages/decision-engines/flows/components/flow-action-button";
import { isFlowPaused } from "src/pages/decision-engines/flows/utils";

export const Loader = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const { data } = useDecisionEngineFlowQuery(
    {
      id: flowId ?? "",
    },
    { select: (data) => data.decision_engine_flows_by_pk, suspense: true },
  );

  if (!data) {
    return <Warning title="Agent not found" />;
  }

  return <FlowContent flow={data} />;
};

export type Flow = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>;

export type FlowContext = OutletContext & {
  flow: Flow;
};

export const FlowContent: FC<
  Readonly<{
    flow: Flow;
  }>
> = ({ flow }) => {
  const context = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const deleteMutation = useDeleteDecisionEngineFlowMutation();
  const update = useUpdateDecisionEngineFlowMutation();

  const flowContext = useMemo(() => ({ flow, ...context }), [flow, context]);

  const availableTabs = useMemo(() => {
    // Don't show tabs when we want to hide the overview tab
    if (flow.status === DecisionEngineStatus.PENDING || isFlowPaused(flow)) {
      return undefined;
    }

    return [
      { path: "overview", title: "Overview" },
      { path: "configuration", title: "Configuration" },
    ];
  }, [flow.status]);

  return (
    <DetailPage
      hasBottomPadding
      bg="base.lightBackground"
      title={flow?.name ?? "Agent"}
      crumbs={[
        {
          label: "All agents",
          link: "/ai/flows",
        },
      ]}
      header={
        <Row justify="space-between" gap={4}>
          <EditableHeading
            value={flow?.name}
            onChange={(name) => {
              update.mutate({
                id: flow?.id,
                input: { name },
              });
            }}
          />
          <Row gap={4} alignItems="center">
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <DeleteButton
                  variant="menu"
                  label="flow"
                  onDelete={() =>
                    deleteMutation.mutateAsync({
                      id: flow.id,
                    })
                  }
                  onSuccess={() => {
                    navigate("/ai/flows");
                  }}
                />
              </MenuList>
            </Menu>
            <FlowActionButton
              id={flow.id}
              status={flow.status as DecisionEngineStatus}
              enabled={flow.enabled}
              isValid={flow.messages.length > 0 && flow.outcomes.length > 0}
              showIcon
            />
          </Row>
        </Row>
      }
      tabs={availableTabs}
      tabDepth={5}
    >
      <Outlet context={flowContext} />
    </DetailPage>
  );
};
