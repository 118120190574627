import { FC, useState } from "react";

import {
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  Menu,
  DeleteIcon,
  PlayIcon,
  Spinner,
  Button,
  useToast,
  ButtonGroup,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Outlet, useNavigate } from "src/router";

import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  PermissionedButton,
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import {
  eventSourceDefinitions,
  eventWarehouseDestinationDefinitions,
} from "src/events/types";
import {
  UpdateEventWarehouseSyncInput,
  useDeleteEventWarehouseSyncMutation,
  useRunEventWarehouseSyncMutation,
  useUpdateEventWarehouseSyncMutation,
} from "src/graphql";
import { WarehouseOutletContext } from "src/events/syncs/common";
import { SyncName } from "src/events/syncs/common/sync-name";
import { useUser } from "src/contexts/user-context";

export type { WarehouseOutletContext as OutletContext };

export const WarehouseSync: FC<
  Readonly<{ sync: WarehouseOutletContext["sync"] }>
> = ({ sync }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { workspace } = useUser();
  const appEventMonitoringEnabled = workspace?.alerting_v2_enabled;
  const [isDeleting, setIsDeleting] = useState(false);

  const updateMutation = useUpdateEventWarehouseSyncMutation();
  const deleteMutation = useDeleteEventWarehouseSyncMutation();
  const runMutation = useRunEventWarehouseSyncMutation({
    onSuccess: () => {
      // skip invalidating queries
    },
  });

  const sourceDefinition = eventSourceDefinitions[sync.event_source.type];
  const destinationDefinition =
    eventWarehouseDestinationDefinitions[sync.event_warehouse_destination.type];
  const lastRun = sync.runs?.[0];
  const isRunning = lastRun && lastRun.started_at && !lastRun.finished_at;

  const startRun = async () => {
    try {
      runMutation.mutateAsync({ id: sync.id });
      toast({
        id: "run-success",
        title: `Sync run has been initiated.`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "run-error",
        title: `There was a problem running your sync.`,
        variant: "error",
      });
    }
  };

  const update = async (input: UpdateEventWarehouseSyncInput) => {
    try {
      await updateMutation.mutateAsync({
        id: sync.id,
        input,
      });

      toast({
        id: "update-event-sync",
        title: `Event sync was updated`,
        variant: "success",
      });
    } catch (_err) {
      toast({
        id: "update-event-sync-error",
        title: `There was a problem updating your event sync.`,
        variant: "error",
      });
    }
  };

  return (
    <DetailPage
      title="Sync"
      crumbs={[{ label: "All syncs", link: "/events/syncs" }]}
      tabDepth={5}
      tabs={[
        {
          title: "Runs",
          path: "runs",
        },
        {
          title: "Archived failures",
          path: "archive",
        },
        {
          title: "Schedule",
          path: "schedule",
        },
        {
          title: "Alerting",
          path: "alerting",
          isHidden: !appEventMonitoringEnabled,
        },
      ]}
      header={
        <Column minWidth={0} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <Column gap={4} align="left">
              {sync.name && (
                <PermissionedEditableHeading
                  permission={{
                    v1: { resource: "workspace", grant: "update" },
                    v2: {
                      resource: "workspace",
                      grant: "can_update",
                    },
                  }}
                  size="lg"
                  value={sync.name}
                  onChange={(name) => {
                    update({ name, schedule: sync.schedule });
                  }}
                />
              )}
              <SyncName
                isWarehouseSync
                sourceDefinition={sourceDefinition}
                destinationDefinition={destinationDefinition}
                source={sync.event_source}
                destination={sync.event_warehouse_destination}
              />
            </Column>
            <ButtonGroup size="lg">
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    permission={{
                      v1: { resource: "workspace", grant: "update" },
                      v2: {
                        resource: "workspace",
                        grant: "can_update",
                      },
                    }}
                    icon={DeleteIcon}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
              {isRunning ? (
                <Button isDisabled>
                  <Spinner size="sm" mr={2} />
                  Running
                </Button>
              ) : (
                <PermissionedButton
                  permission={{
                    v1: { resource: "workspace", grant: "update" },
                    v2: {
                      resource: "workspace",
                      grant: "can_update",
                    },
                  }}
                  icon={PlayIcon}
                  onClick={() => {
                    startRun();
                  }}
                >
                  Run
                </PermissionedButton>
              )}
            </ButtonGroup>
          </Row>
        </Column>
      }
    >
      <Outlet context={{ sync, type: "warehouse" } as WarehouseOutletContext} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="sync"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: sync.id });
          navigate("/events/syncs");
        }}
      />
    </DetailPage>
  );
};
