import { useState } from "react";

import {
  Text,
  Box,
  Row,
  Column,
  InformationIcon,
  Tooltip,
  Skeleton,
  SkeletonBox,
} from "@hightouchio/ui";
import { subDays } from "date-fns";
import NumberFlow from "@number-flow/react";

import { Card } from "src/components/card";
import { useUser } from "src/contexts/user-context";
import { useHomeAudienceSummaryQuery } from "src/graphql/types";
import { AudienceSummaryFilter } from "./audience-summary";
import { RouterLink } from "src/router";

export const AudienceStats = ({
  filter,
}: {
  filter: AudienceSummaryFilter;
}) => {
  const { user } = useUser();
  const [since] = useState(subDays(new Date(), 30).toISOString());

  const personalQuery = useHomeAudienceSummaryQuery({
    filter: {
      created_by_user: { id: { _eq: user?.id?.toString() } },
    },
    since,
  });

  const allQuery = useHomeAudienceSummaryQuery({
    filter: {},
    since,
  });

  const isLoading =
    filter === "personal" ? personalQuery.isLoading : allQuery.isLoading;

  const data = filter === "personal" ? personalQuery.data : allQuery.data;

  return (
    <Skeleton isLoading={isLoading}>
      <Box
        as={Card}
        flexDirection="row"
        py={4}
        px={0}
        boxShadow="md"
        display={["grid", "grid", "grid", "grid", "flex"]}
        gridTemplateColumns={[
          "repeat(auto-fit, minmax(160px, 1fr))",
          "repeat(auto-fit, minmax(160px, 1fr))",
          "repeat(auto-fit, minmax(160px, 1fr))",
          "repeat(auto-fit, minmax(160px, 1fr))",
          "none",
        ]}
      >
        <Stat
          href={
            filter === "personal"
              ? `/audiences?created=${user?.id}`
              : "/audiences"
          }
          label={filter === "personal" ? "My audiences" : "All audiences"}
          value={data?.allCount.aggregate?.count}
          tip={
            filter === "personal"
              ? "All audiences that have been created by you."
              : "All audiences that have been created in this workspace."
          }
        />
        <BorderLine />
        <Stat
          href={
            filter === "personal"
              ? `/audiences?sort=created_at&dir=desc&created=${user?.id}`
              : "/audiences?sort=created_at&dir=desc"
          }
          label="Recently created"
          value={data?.recentlyCreatedCount.aggregate?.count}
          color="electric.base"
          tip="Audiences that were created in the past month."
        />
        <BorderLine />
        <Stat
          href={
            filter === "personal"
              ? `/audiences?recently_synced=30d&created=${user?.id}`
              : "/audiences?recently_synced=30d"
          }
          label="Recently synced"
          value={data?.recentlySyncedCount.aggregate?.count}
          color="success.base"
          tip="Audiences synced to one or more destinations in the past month."
        />
        <BorderLine />
        <Stat
          href={
            filter === "personal"
              ? `/audiences?unhealthy_syncs=true&created=${user?.id}`
              : "/audiences?unhealthy_syncs=true"
          }
          label="With unhealthy syncs"
          value={data?.unhealthyCount.aggregate?.count}
          color="danger.600"
          tip="Audiences connected to one or more syncs with critical alerts or fatal errors."
        />
        <BorderLine />
        <Stat
          href={
            filter === "personal"
              ? `/audiences?inactive_syncs=true&created=${user?.id}`
              : "/audiences?inactive_syncs=true"
          }
          label="Inactive"
          value={data?.inactiveCount.aggregate?.count}
          color="text.tertiary"
          tip="Audiences that are not connected to any syncs (or are connected only to draft or disabled syncs)."
        />
      </Box>
    </Skeleton>
  );
};

const BorderLine = () => {
  return (
    <Box
      display={["none", "none", "none", "none", "block"]}
      w="1px"
      bg="base.border"
    />
  );
};

const Stat = ({
  label,
  value,
  color,
  tip,
  href,
}: {
  label: string;
  value: number | undefined;
  color?: string;
  tip?: string;
  href: string;
}) => (
  <SkeletonBox
    display="flex"
    flexDirection="column"
    justifyContent="center"
    mx={4}
    height="80px"
    flex={1}
    transition="all 300ms ease-in-out"
    _hover={{ bg: "forest.100" }}
    borderRadius="md"
  >
    <Column as={RouterLink} to={href} borderRadius="md" px={4}>
      <Row align="center" gap={1}>
        <Text color="text.secondary" whiteSpace="nowrap">
          {label}
        </Text>
        <Tooltip message={tip} placement="top">
          <Box as={InformationIcon} flexShrink={0} color="text.secondary" />
        </Tooltip>
      </Row>
      <Box
        fontSize="3xl"
        fontWeight="semibold"
        color={value === 0 ? "text.tertiary" : color}
      >
        {value && <NumberFlow value={value} />}
      </Box>
    </Column>
  </SkeletonBox>
);
