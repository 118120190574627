export const Icon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.00004 15.8333H7.50004V10.8333H12.5V15.8333H15V8.33333L10 4.58333L5.00004 8.33333V15.8333ZM3.33337 17.5V7.5L10 2.5L16.6667 7.5V17.5H10.8334V12.5H9.16671V17.5H3.33337Z" />
  </svg>
);
