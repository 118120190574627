import {
  DecisionEngineFlowRunStatus,
  DecisionEngineStatus,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { DEFAULT_DECISION_ENGINE_ACTION_FEATURES_COLUMNS } from "@hightouch/lib/query/visual/types";
import { Column, Row } from "@hightouchio/ui";
import { useDecisionEngineFlowRunQuery } from "src/graphql";
import { GroupByColumn } from "src/pages/analytics/types";
import { Navigate, useOutletContext } from "src/router";
import { FlowContext } from ".";
import {
  isFlowPaused,
  MESSAGE_GROUP_BY_COLUMN,
} from "src/pages/decision-engines/flows/utils";
import { OverviewSidebar } from "./components/overview-sidebar";
import { SendsByBreakdownChart } from "./components/sends-by-breakdown-chart";
import { SendsOverTimeChart } from "./components/sends-over-time-chart";
import { SendsSummary } from "./components/sends-summary";
import { Initializing } from "./components/set-up";
import { OutcomeOverviewTable } from "./components/outcome-overview-table";
import { useFlags } from "launchdarkly-react-client-sdk";

const SendsBreakdownByOptions: { label: string; value: GroupByColumn }[] =
  DEFAULT_DECISION_ENGINE_ACTION_FEATURES_COLUMNS.map((c) => ({
    label: c.alias ?? c.name,
    value: c,
  }));

export const FlowOverview = () => {
  const { flow } = useOutletContext<FlowContext>();
  const { appAidExperimentOverviewEnabled } = useFlags();

  if (flow.status === DecisionEngineStatus.PENDING || isFlowPaused(flow)) {
    return <Navigate to="../configuration" replace />;
  }

  const { data: lastFlowRun, isLoading: isLastFlowRunLoading } =
    useDecisionEngineFlowRunQuery(
      {
        flowId: flow.id,
        orderBy: { started_at: "desc" },
        // Want to explicitly filter for last successful flow run since we can assume
        // that's when we last cached the recent analytics data
        filters: {
          status: { _eq: DecisionEngineFlowRunStatus.Success },
        },
      },
      {
        enabled: flow.status === DecisionEngineStatus.READY,
        select: (data) => data.decision_engine_flow_runs[0],
      },
    );

  // Want to reference the last flow run started_at as a reference point
  // since we cache analytic queries at the end of the decision engine flow run
  const lastFlowRunStartedAt = lastFlowRun?.started_at;

  const flowStartDate = flow.config.flow_start_date as
    | string
    | undefined
    | null;

  const { data: firstFlowRun, isLoading: isFirstFlowRunLoading } =
    useDecisionEngineFlowRunQuery(
      {
        flowId: flow.id,
        orderBy: { started_at: "asc" },
      },
      {
        // If no specified flow start date, use the first flow run started_at
        enabled: flow.status === DecisionEngineStatus.READY && !flowStartDate,
        select: (data) => data.decision_engine_flow_runs[0],
      },
    );

  const allTimeStartDate = flowStartDate ?? firstFlowRun?.started_at;

  return (
    <Row gap={6} justify="space-between">
      {flow.status === DecisionEngineStatus.TRAINING ? (
        <Initializing />
      ) : (
        <Column gap={6} flex="1">
          {appAidExperimentOverviewEnabled && (
            <OutcomeOverviewTable
              flow={flow}
              enabled={!isFirstFlowRunLoading && !isLastFlowRunLoading}
              lastFlowRunStartedAt={lastFlowRunStartedAt}
              allTimeStartDate={allTimeStartDate}
            />
          )}
          <SendsSummary
            flowId={flow.id}
            parentModelId={flow.audience?.parent?.id}
            firstFlowRunStartedAt={allTimeStartDate}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
          />
          <SendsOverTimeChart
            flowId={flow.id}
            parentModelId={flow.audience?.parent?.id}
            flowMessages={flow.messages}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
            allTimeStartDate={allTimeStartDate}
            enabled={!isFirstFlowRunLoading && !isLastFlowRunLoading}
          />
          <SendsByBreakdownChart
            flowId={flow.id}
            parentModelId={flow.audience?.parent?.id}
            flowMessages={flow.messages}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
            allTimeStartDate={allTimeStartDate}
            breakdownOptions={SendsBreakdownByOptions}
            defaultBreakdownColumn={MESSAGE_GROUP_BY_COLUMN}
            enabled={!isFirstFlowRunLoading && !isLastFlowRunLoading}
          />
        </Column>
      )}
      <OverviewSidebar flow={flow} />
    </Row>
  );
};
