import { useEffect } from "react";
import { Heading, PlusIcon, Row, SearchInput } from "@hightouchio/ui";
import { Page } from "src/components/layout";
import { RouteTabs, useTabState } from "src/components/route-tabs";
import { AudienceTemplates } from "./audience-templates";
import {
  LinkButton,
  Navigate,
  Outlet,
  Route,
  Routes,
  useSearchParams,
} from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CreateAudienceTemplate } from "./audience-templates/create";
import {
  AudienceTemplateLoader,
  AudienceTemplate,
} from "./audience-templates/show";
import { SyncTemplates } from "./sync-templates";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { CloneAudienceTemplate } from "./audience-templates/clone";

const TABS = [
  { title: "Audience templates", path: "audience-templates" },
  { title: "Sync templates", path: "sync-templates" },
];

const redirectAudienceTemplates = "/templates/audience-templates";

const Layout = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { activeTab } = useTabState(TABS);
  const variant =
    activeTab?.path === "audience-templates"
      ? {
          resource: "audience template",
          newResourcePath: "audience-templates/new",
        }
      : {
          resource: "sync template",
          newResourcePath: "/schema-v2/settings/sync-templates/new",
        };

  // Clear out the search field when the tab changes
  useEffect(() => {
    if (activeTab?.title) {
      setSearchParams((params) => {
        params.delete("search");
        return params;
      });
    }
  }, [activeTab?.title]);

  return (
    <Page title="Templates" fullWidth sx={{ m: 0, p: 0, pb: 6 }}>
      <Row justifyContent="space-between" p={6}>
        <Heading size="xl">Templates</Heading>
        <Row gap={4}>
          <SearchInput
            placeholder="Search by name..."
            value={searchParams.get("search") ?? ""}
            onChange={(event) =>
              setSearchParams({ search: event.target.value })
            }
          />
          <LinkButton
            href={variant.newResourcePath}
            icon={PlusIcon}
            variant="primary"
          >
            Add {variant.resource}
          </LinkButton>
        </Row>
      </Row>
      <Row borderBottom="1px solid" borderColor="base.border" px={6} mb={6}>
        <RouteTabs tabs={TABS} />
      </Row>
      <Outlet />
    </Page>
  );
};

export const TemplatesRoutes = () => {
  const { audienceTemplatesEnabled } = useFlags();

  return (
    <Routes>
      <Route
        element={
          // Redirect if the feature flag is disabled
          audienceTemplatesEnabled ? <Layout /> : <Navigate to="/" replace />
        }
      >
        <Route
          index
          element={
            <Navigate
              to={{ pathname: "audience-templates", search: location.search }}
              replace
            />
          }
        />
        <Route path="audience-templates" element={<AudienceTemplates />} />
        <Route
          path="audience-templates/new"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect={redirectAudienceTemplates}
            >
              <FormErrorProvider>
                <CreateAudienceTemplate />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route path="sync-templates" element={<SyncTemplates />} />
      </Route>
      <Route element={<AudienceTemplateLoader />}>
        <Route
          path="audience-templates/:id"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "read" },
              }}
              redirect={redirectAudienceTemplates}
            >
              <FormErrorProvider>
                <AudienceTemplate />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route
          path="audience-templates/:id/clone"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect={redirectAudienceTemplates}
            >
              <CloneAudienceTemplate />
            </PermissionedRoute>
          }
        />
      </Route>
    </Routes>
  );
};
