import {
  IntervalSchedule,
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";
import { JourneyNodeType } from "src/types/journeys";
import { ConditionType } from "src/types/visual";
import { JourneyExitCriteriaConfig } from "src/types/journeys";

import { JourneyState } from "./types";

export const DRAWER_WIDTH = 672;
export const NODE_WIDTH = 284;
export const BASE_NODE_HEIGHT = 100;
export const NODE_DETAIL_HEIGHT = 50;
export const NODE_SPACING = 100;
export const MINI_NODE_MIN_WIDTH = 100;
export const MINI_NODE_MIN_HEIGHT = 50;
export const MINI_NODE_INITIAL_SPACING = 150;
export const HANDLE_SIZE = 16;
export const MAX_CARD_WIDTH = 348;
export const VISIBLE_SYNC_COUNT = 3;
export const CLONE_NODE_OFFSET = 150;

export const LIVE_STATES: JourneyState[] = ["live", "draining"];

export enum JourneyFilterType {
  // Draining and paused are purposely left out
  ALL = "all",
  DRAFTS = "draft",
  LIVE = "live",
  OFF = "off",
}

export const JOURNEY_ENTRY_NODES = [
  JourneyNodeType.EntryEvent,
  JourneyNodeType.EntryCohort,
];

export const JOURNEY_NODES_WITH_BRANCH_NODES = [
  JourneyNodeType.Segments,
  JourneyNodeType.WaitUntilEvent,
  JourneyNodeType.Splits,
];

export const JOURNEY_BRANCH_NODES = [
  JourneyNodeType.SegmentBranch,
  JourneyNodeType.WaitUntilEventBranch,
  JourneyNodeType.SplitBranch,
];

export const PARENT_NODE_TO_BRANCH_NODE = {
  [JourneyNodeType.Segments]: JourneyNodeType.SegmentBranch,
  [JourneyNodeType.WaitUntilEvent]: JourneyNodeType.WaitUntilEventBranch,
  [JourneyNodeType.Splits]: JourneyNodeType.SplitBranch,
};

// Actions that should not dirty the form.
export const NOOP_REACTFLOW_CHANGES = ["dimensions", "select", "position"];

export const DEFAULT_INTERVAL_SCHEDULE: IntervalSchedule = {
  type: ScheduleType.INTERVAL,
  schedule: {
    interval: {
      quantity: 1,
      unit: ScheduleIntervalUnit.HOUR,
    },
  },
};

export const NULL_EXIT_CRITERIA: JourneyExitCriteriaConfig = {
  type: ConditionType.And,
  conditions: [],
};

export const PERFORMANCE_TABLE_HEADER_STYLES = {
  mb: 1,
  textTransform: "none",
  borderBottom: "1px solid",
};

export const PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES = {
  display: "flex",
  justifyContent: "flex-end",
  paddingRight: "4 !important",
};

export const PERFORMANCE_TABLE_CELL_STYLES = {
  borderBottom: "none",
};

export const PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES = {
  display: "flex",
  justifyContent: "end",
};
