import { isEmpty } from "lodash";
import { FC, ReactNode, useMemo } from "react";

import {
  Column,
  Paragraph,
  Row,
  Spinner,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { Card } from "src/components/card";
import { useMetricSeries } from "src/pages/analytics/hooks/use-metric-series";
import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";
import { TimeMap } from "src/pages/analytics/state/constants";
import {
  GroupByColumn,
  ParentModel,
  TimeOptions,
} from "src/pages/analytics/types";
import {
  AnalyticsFrequency,
  AndOrCondition,
  PropertyCondition,
} from "src/types/visual";
import { Flow } from "..";
import {
  getDateRangeForMetricSeries,
  getMetricSeriesArgsForInteractions,
  StartDateRangeInput,
} from "../../utils";

export type FlowChartTimeOptions = TimeOptions | "all-time";

const AvailableTimeOptions = [
  { label: "7d", value: TimeOptions.SevenDays },
  { label: "30d", value: TimeOptions.ThirtyDays },
  { label: "60d", value: TimeOptions.SixtyDays },
  { label: "90d", value: TimeOptions.NinetyDays },
  // AID custom option to query all time data from the start of the flow
  { label: "All time", value: "all-time" },
];

type FlowChartWrapperProps = {
  header: ReactNode;
  timeValue: FlowChartTimeOptions;
  setTimeValue: (value: FlowChartTimeOptions) => void;
  allTimeStartDate: string | null | undefined;
  lastFlowRunStartedAt: string | null | undefined;
  flowId: string;
  parentModelId: string | number | undefined | null;
  groupByColumns: GroupByColumn[];
  frequency: AnalyticsFrequency;
  conditions: AndOrCondition<PropertyCondition>[];
  flowMessages: Flow["messages"];
  transformForPerformance: boolean;
  enabled: boolean;
  children: (props: {
    data: ReturnType<typeof transformMetricDataForGraph>["series"];
  }) => ReactNode;
};

export const FlowChartWrapper: FC<FlowChartWrapperProps> = ({
  header,
  timeValue,
  setTimeValue,
  allTimeStartDate,
  flowId,
  parentModelId,
  groupByColumns,
  frequency,
  conditions,
  flowMessages,
  lastFlowRunStartedAt,
  transformForPerformance,
  enabled,
  children,
}: FlowChartWrapperProps) => {
  const startDate: StartDateRangeInput =
    timeValue === "all-time"
      ? { type: "custom", startDate: allTimeStartDate }
      : { type: "lookback", seconds: TimeMap[timeValue] };

  const { data, isPolling, pollingError, errors } = useMetricSeries(
    getMetricSeriesArgsForInteractions({
      enabled,
      flowId,
      parentModelId,
      groupByColumns,
      customDateRange: getDateRangeForMetricSeries(
        lastFlowRunStartedAt,
        startDate,
      ),
      frequency,
      conditions,
      flowMessages,
    }),
  );

  const graph = useMemo(() => {
    return transformMetricDataForGraph({
      groupByColumns,
      metricResults: data,
      parent: { id: parentModelId } as unknown as ParentModel,
      transformForPerformance,
      // Below isn't necessary for the flow charts since metricNames for
      // decision engine interactions metrics are handled explicitly
      audiences: [],
      events: [],
      metrics: [],
      availableMetricsOptions: [],
    });
  }, [data, groupByColumns, parentModelId, transformForPerformance]);

  const queryErrorMessage =
    pollingError || !isEmpty(errors) ? "Query failed" : undefined;

  return (
    <Card gap={4}>
      <Row justify="space-between">
        {header}
        <ToggleButtonGroup
          size="sm"
          value={timeValue}
          onChange={(value) => {
            setTimeValue(value as FlowChartTimeOptions);
          }}
        >
          {AvailableTimeOptions.map((option) => (
            <ToggleButton key={option.value} {...option} />
          ))}
        </ToggleButtonGroup>
      </Row>
      {isPolling ? (
        <Column align="center" justifyContent="center" gap={4} minHeight="sm">
          <Spinner size="lg" />
          <Text color="text.secondary" size="sm">
            Query processing...
          </Text>
        </Column>
      ) : queryErrorMessage ? (
        <Column align="center" justifyContent="center" gap={4} minHeight="sm">
          <AnalyticsPlaceholderImage />
          <Paragraph>{queryErrorMessage}</Paragraph>
        </Column>
      ) : (
        children({ data: graph.series })
      )}
    </Card>
  );
};
