export const topLevelRoutes = [
  "onboarding",
  "syncs",
  "models",
  "destinations",
  "sources",
  "settings",
  "audiences",
  "extensions",
  "sequences",
  "partner-connect",
  "schema",
  "schema-v2",
  "analytics",
  "traits",
  "organization",
  "events",
  "partner",
  "home",
  "copilot",
  "journeys",
  "metrics",
  "governance",
  "invalid",
  "misc",
];

export const homeRoute = "/overview";
