import { type FC, useEffect, useState } from "react";

import { Button, Row, SearchInput } from "@hightouchio/ui";

import { Outlet, useNavigate, useSearchParams } from "src/router";
import { DetailPage } from "src/components/layout";
import { CreateJourneyModal } from "./forms/create-journey-modal";
import type { CloneJourneyInput } from "./types";
import { JourneysTable } from "./journeys-table";
import { homeRoute } from "src/components/router/constants";
import { useEntitlements } from "src/hooks/use-entitlement";

export const JourneysLayout: FC = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const appEnableJourneys = entitlementsData.entitlements.journeys;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sourceJourney, setSourceJourney] = useState<CloneJourneyInput>();

  const onClone = (journeyInput: CloneJourneyInput) => {
    setSourceJourney(journeyInput);
  };

  useEffect(() => {
    if (!appEnableJourneys) {
      navigate(homeRoute);
    }
  }, [appEnableJourneys]);

  return (
    <DetailPage
      heading="Journeys"
      size="full"
      actions={
        <Row gap={2}>
          <SearchInput
            placeholder="Search by name..."
            value={searchParams.get("search") ?? ""}
            onChange={(e) => {
              setSearchParams({ search: e.target.value });
            }}
          />
          <Button
            variant="primary"
            onClick={() =>
              navigate({
                pathname: "/journeys/new",
                search: searchParams.toString(),
              })
            }
          >
            Add journey
          </Button>
        </Row>
      }
    >
      <Outlet />
      <JourneysTable onClone={onClone} />
      <CreateJourneyModal
        isOpen={Boolean(sourceJourney)}
        cloneJourneyParams={sourceJourney}
        closeModal={() => setSourceJourney(undefined)}
      />
    </DetailPage>
  );
};
