import { FC } from "react";
import { Link } from "src/router";
import {
  MergedSyncRequestEventSpan,
  MergedSyncRequestEventStatus,
} from "src/graphql";
import * as time from "src/utils/time";

import {
  Badge,
  ChangeIcon,
  Column,
  ExternalLinkIcon,
  InformationIcon,
  PlusIcon,
  Row,
  SparkleIcon,
  SubtractIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import {
  DestinationIcon,
  SourceIcon,
} from "src/pages/syncs/sync/run/summary/icons";
import { getSyncRunOperations } from "src/utils/syncs";
import {
  MultiValueProgressBar,
  PlaceholderProgressBar,
} from "src/pages/syncs/sync/run/summary/multi-value-progress-bar";

import { BaseSyncRunPhase, SyncRequest, type SyncRunPhase } from "./types";
import { NumericValue } from "./numeric-value";
import { map, pickBy } from "lodash";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useFlags } from "launchdarkly-react-client-sdk";

type SyncBehavior = { add: boolean; change: boolean; remove: boolean };

export enum PhaseGroup {
  Prepare,
  Query,
  Sync,
  Finalize,
}

export const PHASE_GROUP_CONFIG: Record<
  PhaseGroup,
  {
    displayName: string;
    Icon?: FC<{ syncRequest: SyncRequest }>;
  }
> = {
  [PhaseGroup.Prepare]: {
    displayName: "Preparing to sync",
  },
  [PhaseGroup.Query]: {
    displayName: "Querying source",
    Icon: SourceIcon,
  },
  [PhaseGroup.Sync]: {
    displayName: "Syncing to destination",
    Icon: DestinationIcon,
  },
  [PhaseGroup.Finalize]: {
    displayName: "Wrapping up",
  },
};

type PhaseComponentProps = {
  phase: SyncRunPhase;
  syncRequest: SyncRequest;
};

export const PHASE_DISPLAY_CONFIG: Record<
  MergedSyncRequestEventSpan,
  {
    group: PhaseGroup;
    displayName: string;
    displayWhenSkipped?: boolean;
    Description: FC<{ lightningEngineEnabled: boolean }>;
    HeaderData?: FC<PhaseComponentProps>;
    HeaderAction?: FC<PhaseComponentProps>;
    AdditionalData?: FC<PhaseComponentProps>;
    // This is a bit of a hack to allow some phases to get access to data from other phases.
    // eg. in ExecuteDestination, we need to access the matchbooster phase to get the duration.
    collectChildPhases?: (phases: BaseSyncRunPhase[]) => BaseSyncRunPhase[];
  } | null
> = {
  [MergedSyncRequestEventSpan.Sync]: null, // Top level parent span
  [MergedSyncRequestEventSpan.GeneratePlan]: null, // Top level parent span
  [MergedSyncRequestEventSpan.LoadEnrichmentData]: null, // Child span to ExecuteDestination
  [MergedSyncRequestEventSpan.Queue]: {
    group: PhaseGroup.Prepare,
    displayName: "Waiting in queue",
    Description: () => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is provisioning a dedicated worker for this sync run. This
          process typically takes just a few seconds but can sometimes take up
          to a few minutes, especially if multiple syncs are starting around the
          same time.
        </Text>
        <Text size="sm" color="text.secondary">
          If a sync remains in the queue longer than that, it’s likely because
          your workspace has hit its concurrency limit—the maximum number of
          syncs that can run simultaneously.
        </Text>
        <Text size="sm" color="text.secondary">
          This limit exists to protect your data warehouse from being overloaded
          with too many queries at once, but can be increased upon request.
        </Text>
      </>
    ),
  },
  [MergedSyncRequestEventSpan.Prepare]: {
    group: PhaseGroup.Prepare,
    displayName: "Initializing sync engine",
    Description: ({ lightningEngineEnabled }) => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is performing a set of preparatory tasks. This includes
          verifying the state of previous sync runs for change capture and,
          depending on your source configuration, establishing a connection to
          the source.{" "}
          {lightningEngineEnabled &&
            "Hightouch may also create temporary tables to store query results before processing begins."}
        </Text>
      </>
    ),
  },
  [MergedSyncRequestEventSpan.PrepareQuery]: {
    group: PhaseGroup.Query,
    displayName: "Setup query",
    Description: () => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is performing a set of preparatory tasks before executing
          the model query.
        </Text>
      </>
    ),
  },
  [MergedSyncRequestEventSpan.ExecuteQuery]: {
    group: PhaseGroup.Query,
    displayName: "Executing query",
    Description: () => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is executing a query to retrieve results for your model,
          audience, journey step, or other data being synced. The speed of this
          step depends primarily on your data warehouse’s performance.
        </Text>
        <Text size="sm" color="text.secondary">
          If the query takes longer than expected, it’s usually due to warehouse
          constraints, such as high query volume or insufficient compute
          resources. The reported duration for this phase reflects the total
          wait time, including any time the query spent in the warehouse queue
          before execution. If your warehouse is overloaded, queries may be
          delayed for several minutes or even hours, extending the overall time
          required for this step.
        </Text>
      </>
    ),
    HeaderData: ({ syncRequest }) => {
      const queryRun = syncRequest.query_run;
      if (!queryRun) return null;

      return <NumericValue value={queryRun.size} label="rows" />;
    },
  },
  [MergedSyncRequestEventSpan.Cdc]: {
    group: PhaseGroup.Query,
    displayName: "Detecting changes",
    displayWhenSkipped: true,
    Description: ({ lightningEngineEnabled }) => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is determining which rows have been added, updated, or
          removed since the last sync run. This will be skipped for sync modes
          like ‘mirror’, ‘all’, ‘archive’, or any other mode that disables
          row-level change data capture.
        </Text>
        {lightningEngineEnabled && (
          <Text size="sm" color="text.secondary">
            When using the Lightning engine, this process runs directly in your
            data warehouse, and its performance depends on your warehouse’s
            available capacity. If this step takes longer than expected, it may
            actually be waiting in a queue behind other queries, meaning the
            reported duration includes both wait time and the actual time needed
            for change detection.
          </Text>
        )}
      </>
    ),
    HeaderAction: ({ phase, syncRequest }) => {
      const source = syncRequest.sync?.segment?.connection;
      if (!source) return null;

      const phaseDurationIsLong = phase.durationMillis.self > 1000 * 60 * 10; // 10 minutes

      const showLightningPrompt =
        phaseDurationIsLong &&
        syncRequest.planner_type !== "inWarehouse" &&
        source.definition.supportsInWarehouseDiffing;

      if (showLightningPrompt) {
        return (
          <Link href={`/sources/${source.id}`} fontSize="sm" isExternal>
            Taking a long time? Switch to the Lightning sync engine.{" "}
            <ExternalLinkIcon />
          </Link>
        );
      }

      return null;
    },
    HeaderData: ({ phase, syncRequest }) => {
      if (phase.isSkipped) {
        return (
          <Row>
            <Text size="sm" color="text.tertiary">
              Skipped for sync modes that disable row-level change data capture.
            </Text>
          </Row>
        );
      }

      const diff = syncRequest.sync_request_diff;
      if (!diff) return null;

      const syncBehavior = syncRequest.sync_behavior as SyncBehavior | null;

      const ignoredOperations = map(
        pickBy(syncBehavior || {}, (value) => value === false),
        (_value, key) => `'${key}'`,
      );

      const listFormat = new Intl.ListFormat("en");
      const tooltipMessage = `This sync is configured to ignore ${listFormat.format(ignoredOperations)} operations.`;

      return (
        <Row gap={2} alignItems="center">
          <NumericValue value={diff.added_count} icon={PlusIcon} />
          <NumericValue value={diff.changed_count} icon={ChangeIcon} />
          <NumericValue value={diff.removed_count} icon={SubtractIcon} />
          {ignoredOperations.length > 0 && (
            <Row fontSize="lg" alignItems="center">
              <Tooltip message={tooltipMessage} placement="right">
                <InformationIcon color="text.secondary" />
              </Tooltip>
            </Row>
          )}
        </Row>
      );
    },
  },
  [MergedSyncRequestEventSpan.PrepareDestination]: {
    group: PhaseGroup.Sync,
    displayName: "Initializing destination",
    Description: () => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is preparing to sync data to your destination. The specific
          steps vary by destination but may include tasks such as creating lists
          or other objects needed to store the incoming records.
        </Text>
      </>
    ),
  },
  [MergedSyncRequestEventSpan.ExecuteDestination]: {
    group: PhaseGroup.Sync,
    displayName: "Sending data",
    collectChildPhases: (phases) =>
      phases.filter(
        (phase) => phase.span === MergedSyncRequestEventSpan.LoadEnrichmentData,
      ),
    Description: () => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is syncing data to your destination, and the time this takes
          depends on several factors. Some destinations enforce rate limits that
          control how quickly data can be sent. Syncs with more rows and columns
          naturally take longer, and certain sync configurations may also
          require additional processing steps. If any rows are rejected,
          Hightouch will retry sending them, which can extend this phase
          further.
        </Text>
        <Text size="sm" color="text.secondary">
          The progress displayed during this step is an estimate, not a live
          update. Progress is only refreshed after each batch has finished
          processing, which, for some destinations, can take several minutes or
          longer. Certain syncs (such as file uploads or “mirror” sync modes)
          will show 0% progress until the entire sync is complete. Syncs using
          Match Booster will take longer, as Hightouch enriches each record with
          additional identifiers before sending it to the destination.
        </Text>
        <Text size="sm" color="text.secondary">
          If you have any questions about sync performance, please reach out.
          We’re here to help!
        </Text>
      </>
    ),
    AdditionalData: ({ phase, syncRequest }) => {
      const { isPermitted: hasDebugPermission } = useResourcePermission({
        v2: {
          resource: "sync",
          grant: "can_debug",
          id: syncRequest.sync?.id,
        },
      });

      const { appRunDebuggerEnabled } = useFlags();
      const canDebug = appRunDebuggerEnabled && hasDebugPermission;
      const diff = syncRequest.sync_request_diff;
      const queryRun = syncRequest.query_run;

      if (!diff || !queryRun) return null;

      const attempt = syncRequest.sync_attempts[0];

      const {
        successful: { total: successful },
        rejected: { total: rejected },
      } = getSyncRunOperations({
        attempt,
        syncRequest,
        queryRun,
      });

      const syncBehavior = syncRequest.sync_behavior as SyncBehavior | null;

      const totalPlanned =
        syncRequest.planner_type === "all"
          ? queryRun.size
          : (syncBehavior?.add ? diff.added_count : 0) +
            (syncBehavior?.change ? diff.changed_count : 0) +
            (syncBehavior?.remove ? diff.removed_count : 0);

      const totalExecuted = successful + rejected;
      const remaining = totalPlanned - totalExecuted;

      // Show a placeholder progress bar if the sync is active but has no progress
      // Especially useful for planner_type === "all" and mirror syncs where there isn't incremental progress to show
      const showPlaceholderProgressBar =
        phase.status === MergedSyncRequestEventStatus.Active &&
        totalPlanned > 0 &&
        totalExecuted === 0;

      const matchboosterPhase = phase.childPhases.find(
        (phase) =>
          phase.span === MergedSyncRequestEventSpan.LoadEnrichmentData &&
          phase.status !== MergedSyncRequestEventStatus.NotStarted,
      );

      const matchboosterDuration =
        matchboosterPhase?.durationMillis.self &&
        time.formatDuration(
          {
            start: 0,
            end: matchboosterPhase.durationMillis.self,
          },
          {
            units: "short",
            granularity: "second",
          },
        );

      return (
        <Column gap={1}>
          {showPlaceholderProgressBar ? (
            <>
              <PlaceholderProgressBar />
              <Text size="sm" color="text.secondary">
                Syncing in progress...
              </Text>
            </>
          ) : (
            <>
              <MultiValueProgressBar
                values={[
                  {
                    value: (successful / totalPlanned) * 100,
                    color: "success.base",
                  },
                  {
                    value: (rejected / totalPlanned) * 100,
                    color: "danger.base",
                  },
                ]}
              />
              {totalPlanned === 0 ? (
                <NumericValue value={0} label="rows to send" />
              ) : (
                totalExecuted > 0 && (
                  <Row justifyContent="space-between">
                    <Row gap={1} alignItems="center">
                      <NumericValue
                        value={successful}
                        label="rows synced successfully"
                      />
                      {canDebug && (
                        <Link fontSize="sm" href="../successful">
                          (View logs)
                        </Link>
                      )}
                      <Text size="sm" color="text.secondary">
                        ·
                      </Text>
                      <NumericValue value={rejected} label="rows rejected" />
                      {canDebug && (
                        <Link fontSize="sm" href="../rejected">
                          (View logs)
                        </Link>
                      )}
                    </Row>
                    {phase.status === MergedSyncRequestEventStatus.Active && (
                      <NumericValue value={remaining} label="rows remaining" />
                    )}
                  </Row>
                )
              )}
            </>
          )}
          {matchboosterPhase && (
            <Row mt={1}>
              <Tooltip message="This destination has Match Booster enabled">
                <Badge size="sm" svgIcon={SparkleIcon} iconColor="warning.400">
                  Match Booster
                  {phase.status === MergedSyncRequestEventStatus.Completed &&
                    ` (completed in ${matchboosterDuration})`}
                </Badge>
              </Tooltip>
            </Row>
          )}
        </Column>
      );
    },
  },
  [MergedSyncRequestEventSpan.Report]: {
    group: PhaseGroup.Finalize,
    displayName: "Saving logs",
    Description: () => (
      <>
        <Text size="sm" color="text.secondary">
          Hightouch is finalizing logs for this sync run. If warehouse sync logs
          are enabled, additional queries will be executed to write logs back to
          your data source. If your warehouse is already handling a high number
          of concurrent queries, this step may take longer than usual.
        </Text>
      </>
    ),
  },
};
