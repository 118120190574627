import { useFlags } from "launchdarkly-react-client-sdk";
import { Suspense, useMemo, useState } from "react";

import {
  Box,
  ClickIcon,
  Column,
  EmptyState,
  Row,
  Select,
  Spinner,
  StarIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { DocsLink } from "src/components/docs-link";
import { IconBox } from "src/components/icon-box";
import { Page } from "src/components/layout/page";
import { PageSidebar } from "src/components/layout/page-sidebar";
import { useDecisionEngineFlowQuery } from "src/graphql";
import { Link, LinkButton, useOutletContext } from "src/router";
import { OutletContext } from "..";
import { InsightsType } from "./types";
import { GlobalBreakdowns } from "./global-breakdowns";

export const InsightsLoader = () => {
  const { decisionInsightsEnabled } = useFlags();
  const { engine } = useOutletContext<OutletContext>();

  // This includes both running and paused agents
  const readyAgents = engine.flows.filter((f) => f.status === "ready");

  if (!readyAgents.length || !decisionInsightsEnabled) {
    return (
      <Page heading="Insights">
        <EmptyState
          imageUrl={genericPlaceholder}
          title="Insights are not available"
          message="Insights will be available once you set up an agent and an outcome"
          actions={
            <LinkButton variant="primary" href="/ai/flows">
              Go to agents
            </LinkButton>
          }
        />
      </Page>
    );
  }

  return <Insights agents={readyAgents} />;
};

export const Insights = ({
  agents,
}: {
  agents: { id: string; name: string }[];
}) => {
  const [insightsType, setInsightsType] = useState<InsightsType>(
    InsightsType.TopPerformers,
  );
  const [agentId, setAgentId] = useState<string | undefined>(agents[0]?.id);

  const { data: agent } = useDecisionEngineFlowQuery(
    {
      id: agentId ?? "",
    },
    {
      enabled: Boolean(agentId),
      select: (data) => data.decision_engine_flows_by_pk,
      suspense: true,
    },
  );

  const primaryOutcome = useMemo(() => {
    const outcomes = agent?.outcomes ?? [];

    // We are currently only generating insights for the top ranked positive outcome
    return outcomes
      .filter((o) => o.outcome.weight.type === "positive")
      .sort((a, b) => b.outcome.weight.value - a.outcome.weight.value)[0]
      ?.outcome;
  }, [agent]);

  return (
    <Page
      heading="Insights"
      fullWidth
      sidebar={
        <PageSidebar
          header={null}
          collapsible={false}
          contentStyles={{
            height: "100%",
          }}
        >
          <Column p={2} flex={1} justifyContent="space-between">
            <Column gap={4}>
              <Column gap={2}>
                <Text color="text.secondary" fontWeight="medium">
                  Agent
                </Text>
                <Select
                  options={agents.map((a) => ({
                    label: a.name,
                    value: a.id,
                  }))}
                  value={agentId}
                  onChange={(value) => {
                    setAgentId(value);
                  }}
                />
              </Column>
              <Column gap={2}>
                <Text color="text.secondary" fontWeight="medium">
                  Key conversion event
                </Text>
                {primaryOutcome ? (
                  <Tooltip message="This is the top ranked positive outcome">
                    <Link
                      href={`/ai/flows/${agentId}/configuration/outcomes/${primaryOutcome.id}`}
                      display="inline-flex"
                      width="fit-content"
                    >
                      <Row gap={2} align="center">
                        <Box as={ClickIcon} boxSize={5} />
                        {primaryOutcome.name}
                      </Row>
                    </Link>
                  </Tooltip>
                ) : (
                  <Text color="text.secondary">
                    No primary outcome for this agent. Configure a positive
                    ranked outcome to generate insights.
                  </Text>
                )}
              </Column>
              <Column gap={2}>
                <Text color="text.secondary" fontWeight="medium">
                  Views
                </Text>
                <Column gap={2}>
                  <InsightsTypeView
                    icon={<StarIcon />}
                    label={InsightsType.TopPerformers}
                    isSelected={insightsType === InsightsType.TopPerformers}
                    onClick={() => setInsightsType(InsightsType.TopPerformers)}
                  />
                  {/* <InsightsTypeView
                  icon={<BulbSuggestionIcon />}
                  label={InsightsType.PersonalizationInsights}
                  isSelected={
                    insightsType === InsightsType.PersonalizationInsights
                  }
                  onClick={() =>
                    setInsightsType(InsightsType.PersonalizationInsights)
                  }
                /> */}
                </Column>
              </Column>
            </Column>
            {/* TODO: Update docs link once we have a dedicated docs page for insights */}
            <DocsLink href="ai-decisioning/overview" label="Insights docs" />
          </Column>
        </PageSidebar>
      }
    >
      <Suspense
        fallback={
          <Column flex={1} align="center" justify="center">
            <Spinner size="lg" />
          </Column>
        }
      >
        {!agentId || !primaryOutcome?.id ? (
          <EmptyState
            imageUrl={genericPlaceholder}
            title="Insights are not available"
            message="Insights will be available once you have an active agent and an outcome configured"
          />
        ) : (
          <>
            {insightsType === InsightsType.TopPerformers && (
              <GlobalBreakdowns
                agentId={agentId}
                outcomeId={primaryOutcome.id}
                agentMessages={agent?.messages ?? []}
              />
            )}
          </>
        )}
      </Suspense>
    </Page>
  );
};

const InsightsTypeView = ({
  icon,
  label,
  isSelected,
  onClick,
}: {
  icon: React.ReactElement;
  label: InsightsType;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <Row
    h="40px"
    px={4}
    gap={2}
    border="1px solid"
    borderColor={isSelected ? "primary.border" : "transparent"}
    borderRadius="md"
    bg={isSelected ? "primary.background" : "transparent"}
    alignItems="center"
    onClick={onClick}
    cursor="pointer"
  >
    <IconBox
      icon={icon}
      color={isSelected ? "primary.base" : "text.secondary"}
      height="20px"
      hideBorder
    />
    <Text
      color={isSelected ? "primary.base" : "text.secondary"}
      fontWeight={isSelected ? "medium" : "normal"}
    >
      {label}
    </Text>
  </Row>
);
